.antialiased{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loader{
  border-top-color: #efefef;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

.container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@-webkit-keyframes spinner{
  0%{ -webkit-transform: rotate(0deg); }
  100%{ -webkit-transform: rotate(360deg); }
}

@keyframes spinner{
  0%{ transform: rotate(0deg); }
  100%{ transform: rotate(360deg); }
}

.grecaptcha-badge {
  display: none !important;
}

.menu-button {
  width: 24px;
  height: 18px;
  position: relative;
}

.menu-button:hover {
  cursor: pointer;
}

.strip {
  transition: 0.5s;
  width: 100%;
  height: 2px;
  background-color: white;
  position: absolute;
  border-radius: 10px;
}

.strip1 {
  top: 0;
}

.strip2 {
  top: 8px;
  left: 0;
}

.strip3 {
  bottom: 0;
}

.change .strip1 {
  top: 8px;
  height: 2px;
  transform: rotate(45deg);
}

.change .strip2 {
  background-color: rgba(0, 0, 0, 0%);
  width: 0;
  height: 0;
  top: 50%;
  left: 25%;
}

.change .strip3 {
  height: 2px;
  top: 8px;
  transform: rotate(-45deg);
}

